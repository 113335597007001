<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="850px"
    >
      <v-card>
        <v-card-title>
          Test Report Entry
          <v-spacer></v-spacer>
          <small>{{ testReceipt != null ? testReceipt.code : '' }}</small>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-simple-table dense class="text-center">
                <template>
                    <thead>
                        <tr>
                            <th class="text-center">Delivery</th>
                            <th class="text-center">Test Name</th>
                            <th class="text-center">Delivery Date</th>
                            <th class="text-center">Delivery Note</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, i) in testReceiptDetails" :key="i">
                            <td>
                              <input 
                                type="checkbox" 
                                v-model="item.isReportDelivered" 
                                :disabled="item.isTestDelivered"
                              >
                            </td>
                            <td>{{ item.testName }}</td>
                            <td>{{ item.deliveryDateTime }}</td>
                            <td>
                              <textarea
                                rows="1"
                                outlined
                                class="mt-1"
                                :disabled="!item.isReportDelivered"
                                v-model="item.reportDeliveredNote"
                              ></textarea>
                            </td>
                            <td>
                              <v-btn
                                color="secondary"
                                x-small
                                :to="`/lab/investigation-report/${item.testReceiptDetailsId}`"
                              >
                                Report
                              </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveDeliveryTest"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import swal from 'sweetalert'
export default {
  props: ["testReceipt"],
  data: () => ({
    dialog: false,
    testReceiptDetails: [],
  }),
  watch: {
    testReceipt() {
      let testReceiptDetailsData = []
      this.testReceipt.test_receipt_details.forEach(el => {
        testReceiptDetailsData.push({
          testReceiptDetailsId: el.id,
          testId: el.test.id,
          testName: el.test.name,
          deliveryDateTime: el.delivery_date_time,
          isReportDelivered: el.report_delivered_at != null ? true : false,
          isTestDelivered: el.report_delivered_at != null ? true : false,
          reportDeliveredNote: el.report_delivered_note,
        })
      })
      this.testReceiptDetails = testReceiptDetailsData
    }
  },
  methods: {
    async saveDeliveryTest() {
      let delivered = this.testReceiptDetails.filter(item => item.isReportDelivered == true)
      if (!delivered.length) return

      let res = await this.$store.dispatch('testReceipt/saveDeliveryTest', {
        details: this.testReceiptDetails, id: this.testReceipt.id
      });

      if (res) this.dialog = false;
    }
  },
}
</script>

<style lang="scss" scoped>
textarea {
  border: 1px solid rgb(180, 180, 180) !important;
  padding: 2px 5px;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: unset;
}
</style>