var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"custom-data-table elevation-1",attrs:{"dense":"","show-expand":"","headers":_vm.testHeaders,"loading":_vm.$store.getters['testReceipt/loading'],"items":_vm.$store.getters['testReceipt/testReceipts'],"search":_vm.searchTestReceipt},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dense":"","color":"white","elevation":1,"height":"30px"}},[_c('v-toolbar-title',{staticClass:"subtitle-2"},[_vm._v("Report Delivery")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-form',{staticClass:"custom-form",on:{"submit":function($event){$event.preventDefault();return (function () { return false; })($event)}}},[_c('v-text-field',{staticStyle:{"width":"300px"},attrs:{"outlined":"","dense":"","hide-details":"","placeholder":"Search Test Receipt","append-icon":"mdi-magnify"},model:{value:(_vm.searchTestReceipt),callback:function ($$v) {_vm.searchTestReceipt=$$v},expression:"searchTestReceipt"}})],1)],1)]},proxy:true},{key:"item.doctor.display_text",fn:function(ref){
var item = ref.item;
return [(item.doctor == null)?_c('span',[_vm._v("N/A")]):_c('span',[_vm._v(_vm._s(item.doctor.display_text))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.testReportDelivery(item)}}},on),[_vm._v("mdi-circle-edit-outline")])]}}],null,true)},[_c('span',[_vm._v("Report Entry")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('table',{staticClass:"details__table"},[_c('tr',[_c('th',[_vm._v("Test Name")]),_c('th',[_vm._v("Room No.")]),_c('th',[_vm._v("Completed At")]),_c('th',[_vm._v("Completed Note")]),_c('th',[_vm._v("Completed By")])]),_vm._l((item.test_receipt_details),function(_item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(_item.test.name))]),_c('td',[_vm._v(_vm._s(_item.test.room_number))]),_c('td',[_vm._v(_vm._s(_item.report_completed_at))]),_c('td',[_vm._v(_vm._s(_item.report_completed_note ? _item.report_completed_note : 'N/A'))]),_c('td',[_vm._v(_vm._s(_item.report_completed_by != null ? _item.report_completed_by.name : 'N/A'))])])})],2)])]}}],null,true)})],1)],1),_c('test-report-delivery-modal',{ref:"testReportDeliveryModal",attrs:{"testReceipt":_vm.testReceipt}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }